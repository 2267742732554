import { connect } from "react-redux";
import React from 'react';
import Currency from 'react-currency-formatter';
import { navActions, statisticsActions } from "./_actions";
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { apiServices } from "./_services";
import { BarChart2, RefreshCw, Save } from "react-feather";
import ReactCountryFlag from "react-country-flag";
import Humanize from "humanize";


class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.props.dispatch(navActions.setTitle('Dashboard'));
    this.props.dispatch(statisticsActions.updateCharts());
    this.props.dispatch(statisticsActions.updateStats());

    // Set the initial component state.
    this.state = {
      //updateTimerId,
      activity: []
    };

    apiServices.axios.get('/analytics/activity.json').then(activity => this.setState({ activity: activity.data }));
  }

  componentWillUnmount() {
    // Unregister the timer - don't need to keep refreshing it while we're not on the dashboard.
    //window.clearInterval(this.state.updateTimerId)
  }

  render() {
    const { account, statistics: stats } = this.props;
    const { activity } = this.state;

    return (
      <div>
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item"><a href="dashboard-four.html#">Dashboard</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Overview</li>
                </ol>
              </nav>
              <h4 className="mg-b-0 tx-spacing--1">Welcome to the dashboard, {account.username}.</h4>
            </div>
            {/*<div className="d-none d-md-block">
              <button className="btn btn-sm pd-x-15 btn-white btn-uppercase"><RefreshCw className="wd-10 mg-r-5" /> Refresh</button>
              <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><Save className="wd-10 mg-r-5" /> Export</button>
            </div>*/}
          </div>

          <div className="row mb-4">
            <div className="col-sm-6 col-lg-2">
              <div className="media">
                <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                  <BarChart2 />
                </div>

                <div className="media-body">
                  <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">Peak Today</h6>
                  <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">{stats.peakToday}</h4>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-2">
              <div className="media">
                <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                  <BarChart2 />
                </div>

                <div className="media-body">
                  <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">New Users</h6>
                  <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">{stats.newPlayersToday}</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-xs">
            <div className="col-lg-12 mg-t-10">
              <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                  <h6 className="lh-5 mg-b-0">Active players</h6>
                  <a href="dashboard-two.html" className="tx-13 link-03">{stats.playerCount} online  <i className="icon ion-ios-arrow-down" /> </a>
                </div>{/* card-header */}
                <div className="card-body pd-y-15 pd-x-10">
                  <div className="table-responsive">
                    <table className="table table-striped table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                      <thead>
                        <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                          <th></th>
                          <th>Name</th>
                          <th>World</th>
                          <th>Level</th>
                          <th>Playtime</th>
                          <th>Session</th>
                          <th>Email</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {stats.players.map(pl => <PlayerListEntry player={pl} key={pl.id} />)}
                      </tbody>
                    </table>
                  </div>{/* table-responsive */}
                </div>{/* card-body */}
              </div>{/* card */}
            </div>
          </div>{/* row */}

          {/*<div className="row">
              <div className="col-md-3 mg-t-10">
        <div className="card card-dashboard-table">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Players</th>
                </tr>
              </thead>
              <tbody>
                  <ActivityList data={activity} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
                              </div> */ }
        </div>{/* container */}
      </div>
    );
  }

}

const PlayerListEntry = React.memo(({ player: pl }) => {
  return (
    <tr key={pl.id}>
      <td><ReactCountryFlag title={pl.country_name} countryCode={pl.country} svg style={{ fontSize: '1.5em', lineHeight: '1.5em', }} /></td>
      <td className="tx-medium"><Link to={`/characters/${pl.id}`}>{pl.name}</Link>{pl.playtime === '00:00:00' && <span className="badge badge-primary ml-2">New</span>} {pl.muted && <span className="badge badge-warning">Muted</span>} {pl.banned && <span className="badge badge-danger">Banned</span>}</td>
      <td>{pl.world}</td>
      <td>{pl.level}</td>
      <td>{pl.playtime}</td>
      <td><SessionTimer date={pl.since} /></td>
      <td>{pl.email}</td>
      <td><button className="btn btn-outline-warning btn-xs" onClick={() => { window.confirm(`Are you sure you want to kick ${pl.name}?`) && apiServices.axios.post(`/characters/${pl.id}/kick`) }}>Kick</button> </td>
      <td><button className="btn btn-outline-danger btn-xs" onClick={() => { window.confirm(`Are you sure you want to forcibly kick ${pl.name}?`) && apiServices.axios.post(`/characters/${pl.id}/forcekick`) }}>Force-kick</button> </td>
    </tr>
  );
});

function ActivityList(props) {
  const entries = props.data;
  const peak = Math.max.apply(Math, entries.map(o => o.avg));
  const low = Math.min.apply(Math, entries.map(o => o.avg));

  return entries.map(e => <tr align="center" key={e.hour}>
    <td>{e.hour}</td>
    <td style={{ backgroundColor: 'hsl(213, 100%, ' + (60 + (40 - ((e.avg - low) / (peak - low) * 40))) + '%)' }}>{e.avg}</td>
  </tr>)
}

const SessionTimer = React.memo(({ date }) => {
  const timerTime = Date.now() - date;
  let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
  let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
  let hours = ("0" + Math.floor(timerTime / 3600000)).slice(-2);
  return <span>{hours}:{minutes}:{seconds}</span>;
});

class PlayerChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      chartKey: 'players24'
    };

    this.switch = this.switch.bind(this);
  }

  switch(key) {
    this.setState({ chartKey: key });
  }

  render() {
    const graph = this.props.data[this.state.chartKey] || [];

    var labels = [];
    var data = [];
    var data_avg = [];
    var data_low = [];

    graph.forEach(a => {
      labels.push(a.date);
      data.push(a.peak.toFixed());
      data_avg.push(a.avg.toFixed());
      data_low.push(a.low.toFixed());
    });

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Peak',
          backgroundColor: 'rgba(1, 104, 250, 0.2)',
          borderColor: 'rgba(1, 104, 250)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(1, 104, 250, 0.4)',
          hoverBorderColor: 'rgba(1, 104, 250, 1)',
          data: data,
          scaleLabel:
            function (label) { return '$' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
        },
        {
          label: 'Average',
          backgroundColor: 'rgba(1, 104, 250, 0.1)',
          borderColor: 'rgba(1, 104, 250)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(1, 104, 250, 0.2)',
          hoverBorderColor: 'rgba(1, 104, 250, 1)',
          data: data_avg,
          scaleLabel:
            function (label) { return '$' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
        },
        {
          label: 'Low',
          backgroundColor: 'rgba(1, 104, 250, 0.1)',
          borderColor: 'rgba(1, 104, 250)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(1, 104, 250, 0.2)',
          hoverBorderColor: 'rgba(1, 104, 250, 1)',
          data: data_low,
          scaleLabel:
            function (label) { return '$' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
        }
      ]
    };

    return (
      <div className="card">
        <div className="card-header d-sm-flex align-items-start justify-content-between">
          <h5 className="lh-5 mg-b-0">Player count chart</h5>

          <div className="btn-group btn-group-sm" role="group">
            <button type="button" className="btn btn-primary" onClick={() => this.switch('players24')}>Last 24H</button>
            <button type="button" className="btn btn-primary" onClick={() => this.switch('players7d')}>Last week</button>
            <button type="button" className="btn btn-primary" onClick={() => this.switch('players31d')}>Last month</button>
            <button type="button" className="btn btn-primary" onClick={() => this.switch('lifetime')}>Lifetime</button>
          </div>
        </div>

        <div className="card-body pd-y-15 pd-x-10">
          <Line data={chartData} options={{ hover: { intersect: false } }} />
        </div>
      </div>
    );
  }

}

class DropoffChart extends React.Component {

  render() {
    const graph = this.props.data || [];

    const labels = [];
    const data = [];

    let total = 0;
    graph.forEach(a => {
      total += a.count;
    });

    graph.forEach(a => {
      labels.push(a.level);
      data.push((a.count / total * 100).toFixed(1));
    });

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Frequency',
          backgroundColor: 'rgba(1, 104, 250, 0.2)',
          borderColor: 'rgba(1, 104, 250)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(1, 104, 250, 0.4)',
          hoverBorderColor: 'rgba(1, 104, 250, 1)',
          data: data
        }
      ]
    };

    return (
      <div className="card">
        <div className="card-header d-sm-flex align-items-start justify-content-between">
          <h5 className="lh-5 mg-b-0">Dropoff Chart</h5>
        </div>

        <div className="card-body pd-y-15 pd-x-10">
          <Bar data={chartData} options={{ hover: { intersect: false } }} />
        </div>
      </div>
    );
  }

}

class LevelDistributionCharts extends React.Component {

  render() {
    const players = this.props.players || [];

    const labels = [];
    const data = [];
    const falloffData = [];
    const count = players.length * 1.0;

    for (let i = 1; i <= 115; i++) {
      labels.push('' + i);
      data.push(0);
      falloffData.push(0);
    }

    players.forEach(a => {
      data[a.level - 1]++;

      for (let lv = 1; lv <= a.level; lv++)
        falloffData[lv - 1]++;
    });

    for (let i = 0; i < falloffData.length; i++) {
      falloffData[i] /= count;
    }

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Frequency',
          backgroundColor: 'rgba(1, 104, 250, 0.2)',
          borderColor: 'rgba(1, 104, 250)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(1, 104, 250, 0.4)',
          hoverBorderColor: 'rgba(1, 104, 250, 1)',
          data: data
        }
      ]
    };

    const falloff = {
      labels: labels,
      datasets: [
        {
          label: 'Frequency',
          backgroundColor: 'rgba(1, 104, 250, 0.2)',
          borderColor: 'rgba(1, 104, 250)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(1, 104, 250, 0.4)',
          hoverBorderColor: 'rgba(1, 104, 250, 1)',
          data: falloffData
        }
      ]
    };

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header d-sm-flex align-items-start justify-content-between">
              <h5 className="lh-5 mg-b-0">Online Level Distribution</h5>
            </div>

            <div className="card-body pd-y-15 pd-x-10">
              <Bar data={chartData} options={{ hover: { intersect: false } }} />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="card-header d-sm-flex align-items-start justify-content-between">
              <h5 className="lh-5 mg-b-0">Level Requirement Inclusion</h5>
            </div>

            <div className="card-body pd-y-15 pd-x-10">
              <Line data={falloff} options={{ hover: { intersect: false } }} />
            </div>
          </div>
        </div>
      </div>
    );
  }

}

function capitalize(string) {
  return string && (string[0].toUpperCase() + string.substr(1))
}

function mapStateToProps(state) {
  return { account: state.authentication.account, statistics: state.statistics };
}

const connected = connect(mapStateToProps)(Dashboard);
export { connected as Dashboard };
