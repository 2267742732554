
import { statisticsConstants } from "../_constants";

const initialState = {
    players: [],
    players1h: [],
    players24: [],
    playersYesterday: 0,
    peakToday: 0,
    newPlayersToday: 0,
    newPlayers1h: [],
    newPlayers6h: [],
};

export function statistics(state = initialState, action) {
    switch (action.type) {
        case statisticsConstants.UPDATE_PLAYER_COUNTS_RESPONSE:
            return {
                ...state,
                players: action.payload.players,
                playersYesterday: action.payload.yesterday,
                playerCount: action.payload.online
            };
        case statisticsConstants.UPDATE_CHARTS_RESPONSE:
            return {
                ...state,
                players24: action.payload.players24,
                players1h: action.payload.players1h,
                players7d: action.payload.players7d,
                players31d: action.payload.players31d,
                lifetime: action.payload.lifetime,
                dropoff: action.payload.dropoff
            };
        case statisticsConstants.UPDATE_STATS_RESPONSE:
            return {
                ...state,
                newPlayersToday: action.payload.newPlayersToday || 0,
                peakToday: action.payload.peakToday || 0,
                newPlayers1h: action.payload.newPlayers1h || [],
                newPlayers6h: action.payload.newPlayers6h || [],
                newPlayers24h: action.payload.newPlayers24h,
                newPlayersWeek: action.payload.newPlayersWeek,
                newPlayersMonthly: action.payload.newPlayersMonthly,
                newPlayers180d: action.payload.newPlayers180d
            }
        default:
            return state;
    }
}
